import { produce } from "immer";
import pipe from "ramda/es/pipe";
import create, { GetState, UseBoundStore } from "zustand";
import { devtools } from "zustand/middleware";

const immer = config => (set, get, api) => config(fn => set(produce(fn)), get, api);

let createStore = pipe(immer, create);

if (process.env.NODE_ENV === "development") {
  createStore = pipe(immer, devtools, create);
}

export const initialState = {
  documents: [],
  totalDocuments: 0,
  documentsSelected: [],
  bookTypes: [],
  bookTypeSelected: undefined,
  companySelected: undefined,
  isCompanySelectOpen: false,
  userData: undefined,
  cachedImages: {},
  showUploadList: false,
  filesToUpload: [],
  totalFilesUploaded: 0,
  selectedIndex: undefined,
  acceptedFileTypes: [],
  isLoading: false,
  previewSrc: [],
  documentSplitType: "DEFAULT",
};

const setStateHelper = (argName, argValue, set, get) => {
  set(state => ({
    ...state,
    [argName]: typeof argValue === "function" ? argValue(get()[argName]) : argValue,
  }));
};

const useStore: UseBoundStore<any> = createStore((set, get: GetState<any>) => ({
  ...initialState,
  resetStore: () => {
    set(() => ({
      ...initialState,
    }));
  },
  setUserData: userData => {
    setStateHelper("userData", userData, set, get);
  },
  setCompanySelected: companySelected => {
    setStateHelper("companySelected", companySelected, set, get);
  },
  setBookTypes: bookTypes => {
    setStateHelper("bookTypes", bookTypes, set, get);
  },
  incrementBookTypesTotal: (bookTypeId, total) => {
    const bookTypes = get().bookTypes;
    const index = bookTypes.findIndex(item => item.bookType === bookTypeId);
    set(state => ({
      ...state,
      bookTypes: produce(bookTypes, draft => {
        draft[index].documentCount += total;
      }),
      totalDocuments: state.totalDocuments + total,
    }));
  },
  setIsCompanySelectOpen: isCompanySelectOpen => {
    setStateHelper("isCompanySelectOpen", isCompanySelectOpen, set, get);
  },
  setBookTypeSelected: bookTypeSelected => {
    setStateHelper("bookTypeSelected", bookTypeSelected, set, get);
  },
  setDocuments: documents => {
    setStateHelper("documents", documents, set, get);
  },
  setTotalDocuments: totalDocuments => {
    setStateHelper("totalDocuments", totalDocuments, set, get);
  },
  setDocumentsSelected: documentsSelected => {
    setStateHelper("documentsSelected", documentsSelected, set, get);
  },
  setDocumentsAllSelected: () => {
    set(state => ({
      ...state,
      documentsSelected: state.documents,
    }));
  },
  setCachedImages: cachedImages => {
    setStateHelper("cachedImages", cachedImages, set, get);
  },
  setShowUploadList: showUploadList => {
    setStateHelper("showUploadList", showUploadList, set, get);
  },
  setFilesToUpload: filesToUpload => {
    setStateHelper("filesToUpload", filesToUpload, set, get);
  },
  resetFilesToUpload: () => {
    set(state => ({
      ...state,
      filesToUpload: [],
    }));
  },
  updateFileStatus: (fileIndex, status) => {
    const filesToUpload = get().filesToUpload;
    if (filesToUpload.length < 1) {
      return;
    }

    set(state => ({
      ...state,
      filesToUpload: produce(filesToUpload, draft => {
        draft[fileIndex].status = status;
      }),
    }));
  },
  incrementTotalFilesUploaded: () => {
    set(state => ({
      ...state,
      totalFilesUploaded: state.totalFilesUploaded + 1,
    }));
  },
  resetTotalFilesUploaded: () => {
    set(state => ({
      ...state,
      totalFilesUploaded: 0,
    }));
  },
  setSelectedIndex: selectedIndex => {
    setStateHelper("selectedIndex", selectedIndex, set, get);
  },
  setAcceptedFileTypes: acceptedFileTypes => {
    setStateHelper("acceptedFileTypes", acceptedFileTypes, set, get);
  },
  setIsLoading: isLoading => {
    setStateHelper("isLoading", isLoading, set, get);
  },
  setPreviewSrc: previewSrc => {
    setStateHelper("previewSrc", previewSrc, set, get);
  },
  setDocumentSplitType: (documentSplitType: DocumentSplitType) => {
    setStateHelper("documentSplitType", documentSplitType, set, get);
  },
}));

export default useStore;
