import { gql } from "@apollo/client";

export const LIST_ACCOUNTING_FIRM_PAGINATED = gql`
  query ($filter: AccountingFirmFilterInput, $limit: Int, $offset: Int, $orderBy: [String]) {
    listAccountingFirmsPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
        companies {
          id
          name
          internalReference
        }
      }
    }
  }
`;

export const LIST_COMPANIES_PAGINATED = gql`
  query ($limit: Int, $offset: Int, $orderBy: [String], $filter: CompanyFilterInput) {
    listCompaniesPaginated(filter: $filter) {
      totalCount
      results(limit: $limit, offset: $offset, orderBy: $orderBy) {
        id
        name
        internalReference
        accountingFirm {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query ($companyID: ID!) {
    getCompany(id: $companyID) {
      id
      name
      internalReference
      accountingFirm {
        id
        name
      }
      settings {
        ssiType
      }
    }
  }
`;

export const GET_COMPANIES_QUERY = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      internalReference
      accountingFirm {
        id
        name
      }
    }
  }
`;

export const LIST_ACCOUNTING_FIRMS_QUERY = gql`
  query listAccountingFirmsPaginated(
    $limit: Int
    $offset: Int
    $filter: AccountingFirmFilterInput
  ) {
    listAccountingFirmsPaginated(filter: $filter) {
      results(limit: $limit, offset: $offset, orderBy: "name") {
        id
        name
        companies {
          id
          name
          internalReference
        }
      }
      totalCount
    }
  }
`;

export const LIST_COMPANIES_QUERY = gql`
  query listCompaniesPaginated($limit: Int, $offset: Int, $filter: CompanyFilterInput) {
    listCompaniesPaginated(filter: $filter) {
      results(limit: $limit, offset: $offset, orderBy: "name") {
        id
        name
        internalReference
        accountingFirm {
          id
          name
        }
      }
      totalCount
    }
  }
`;

export const GET_ACCOUNTING_FIRM = gql`
  query ($accountingFirmId: ID!) {
    getAccountingFirm(id: $accountingFirmId) {
      id
      name
    }
  }
`;
