import { useLazyQuery } from "@apollo/client";
import { AccountingFirmsOptions, HeaderWithOptions } from "@newrai/ant-design";
import {
  clientCentralApi,
  clientNeoActivite,
  clientNeoActiviteWS,
  clientNeoAuth,
} from "@newrai/api-clients";
import { Layout, notification } from "antd";
import axios from "axios";
import { langsCode, langsLabel } from "config/i18n/i18n";
import { routes } from "config/routes";
import Settings, { APP_URL, SEARCH_LIMIT } from "config/settings";
import useStore from "config/store";
import i18n from "i18next";
import Cookies from "js-cookie";
import {
  GET_ACCOUNTING_FIRM,
  GET_COMPANY,
  LIST_ACCOUNTING_FIRMS_QUERY,
  LIST_COMPANIES_QUERY,
} from "queries/centralapi";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NeoScanLogo from "resources/images/neoscan.png";
import { createAccountingFirmOption, createCompanyOption } from "utils/selectOptions";
import AppsMenu from "./AppsMenu";

type ID = string | number | undefined;

function HeaderWrapper(): React.ReactElement<any, any> {
  const { t } = useTranslation();

  const setIsCompanySelectOpen = useStore(state => state.setIsCompanySelectOpen);
  const isCompanySelectOpen = useStore(state => state.isCompanySelectOpen);
  const setCompanySelected = useStore(state => state.setCompanySelected);
  const companySelected = useStore(state => state.companySelected);
  const resetStore = useStore(state => state.resetStore);
  const userData = useStore(state => state.userData);
  const companyId = useRef<ID>("");
  const isNewrai = userData?.isNewraiUser;

  const { companyID } = useParams();

  const [language, setLanguage] = useState(i18n.language);

  const [getCompany] = useLazyQuery<GetCompanyResponseType, GetCompanyInputType>(GET_COMPANY, {
    client: clientCentralApi,
    onCompleted: ({ getCompany }) => {
      setIsCompanySelectOpen(false);
      const newCompany = getCompany;

      if (newCompany) {
        setIsCompanySelectOpen(false);
        setCompanySelected({
          accountingFirm: newCompany?.accountingFirm,
          accountingFirmId: newCompany?.accountingFirm?.id,
          company: newCompany,
          companyId: newCompany?.id,
        });
        routes.navigate(`/company/${newCompany?.id}`);
      } else {
        routes.navigate("/");
        notification.error({
          message: t("Company not found"),
        });
      }
    },
  });

  const [getAccountingFirm] = useLazyQuery<
    GetAccountingFirmResponseType,
    GetAccountingFirmInputType
  >(GET_ACCOUNTING_FIRM, {
    client: clientCentralApi,
    onCompleted: ({ getAccountingFirm }) => {
      setCompanySelected({
        accountingFirm: getAccountingFirm,
        accountingFirmId: getAccountingFirm?.id,
      });
    },
  });

  useEffect(() => {
    if (userData) {
      const { companies, accountingFirms } = userData;
      const companyId = (companies && companies.length === 1 && companies[0]) || companyID;
      const accountingFirmId =
        accountingFirms && accountingFirms.length === 1 && accountingFirms[0];

      if (companyId) {
        getCompany({
          variables: {
            companyID: companyId,
          },
        });
      } else if (accountingFirmId) {
        getAccountingFirm({
          variables: {
            accountingFirmId: accountingFirmId,
          },
        });
      }
      setIsCompanySelectOpen(true);
    } else {
      routes.navigate(`/error`, { replace: true });
    }
  }, [companyID]);

  const loadAccountingFirmsOptions = async (
    search = "",
    prevOptions: Record<string, unknown>[],
  ): Promise<Record<string, unknown>> => {
    let hasMore = false;
    const variables = {
      limit: SEARCH_LIMIT,
      offset: prevOptions.length,
      filter: {
        companies_Isnull: false,
        name_Icontains: search,
      },
    };

    const response = await getMoreData({
      query: LIST_ACCOUNTING_FIRMS_QUERY,
      dataName: "listAccountingFirmsPaginated",
      variables,
    });
    const resultsList = response.results;

    const filteredList = resultsList.filter(
      (item: { companies: Companies[] }) => item.companies.length > 0,
    );
    const options = filteredList.map((accountingFirmItem: { name: string }) => {
      return createAccountingFirmOption(accountingFirmItem);
    });

    hasMore = resultsList.length < response.totalCount;
    return { options, hasMore };
  };

  const loadCompaniesOptions = async (
    search = "",
    prevOptions: Record<string, unknown>[],
    accountingFirms: AccountingFirmsOptions | undefined,
  ): Promise<Record<string, unknown>> => {
    let hasMore = false;
    const selectedAccountingFirmId = accountingFirms?.selectedOption?.value?.id;
    const accountingFirmId = Number(selectedAccountingFirmId);
    const variables = {
      limit: SEARCH_LIMIT,
      offset: prevOptions.length,
      filter: {
        accountingFirm: accountingFirmId,
        nameOrInternalReference: search,
      },
    };

    const response = await getMoreData({
      query: LIST_COMPANIES_QUERY,
      dataName: "listCompaniesPaginated",
      variables,
    });
    const resultsList = response.results;
    const options = resultsList.map((companyItem: CompanyItem) => createCompanyOption(companyItem));

    hasMore = resultsList.length < response.totalCount;
    return { options, hasMore };
  };

  const getMoreData = ({ query, dataName, variables }: GetMoreData): Promise<any> => {
    return clientCentralApi
      .query({
        query,
        variables,
      })
      .then(({ data }) => {
        return data[dataName];
      });
  };

  const saveCompany = async (companyData: CompaniesWithAccountingFirm) => {
    if (companyId.current !== companyData.id) {
      companyId.current = companyData.id;
    }
    setCompanySelected({
      accountingFirm: companyData.accountingFirm!,
      accountingFirmId: companyData?.accountingFirm?.id,
      company: companyData,
      companyId: companyData.id,
    });
    setIsCompanySelectOpen(false);

    routes.navigate(`/company/${companyData.id}`);
  };

  const changeLanguage = (lang: string): void => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const handleLogout = async () => {
    const resetStores = (): void => {
      clientNeoAuth.resetStore();
      clientCentralApi.resetStore();
      resetStore();
    };

    const resetCookies = (): void => {
      Cookies.remove("access_token");
      Cookies.remove("expireDate");
    };

    await axios(Settings.revokeToken, {
      method: "post",
      data: `token=${Cookies.get("access_token")}&client_id=${Settings.clientId}`,
    }).then(() => {
      resetStores();
      resetCookies();
      routes.navigate("/");
    });
  };

  const languageOptions =
    process.env.NODE_ENV === "development"
      ? {
          changeLanguage: (e): void => changeLanguage(e),
          languageCodes: langsCode,
          languageLabels: langsLabel(),
          selectedLanguage: language,
        }
      : undefined;

  const canUpdateCompany =
    userData?.permissions &&
    userData?.permissions?.find((item: string) => item === "dashboard.can-display-companies");

  return (
    <Layout>
      <AppsMenu />
      <HeaderWithOptions
        avatarOptions={{
          firstName: userData?.firstName,
          lastName: userData?.lastName,
          userCategory: userData?.userCategory?.name,
          onLogout: handleLogout,
        }}
        {...(companyID && canUpdateCompany
          ? {
              goToDashboardOptions: {
                url: `${Settings.dashboard}companies/${companyID}/general/general-info`,
              },
            }
          : {})}
        languageOptions={languageOptions}
        logoOptions={{
          src: NeoScanLogo,
          alt: "NeoScan Pro",
        }}
        selectCompanyOptions={{
          accountingFirmValue: companySelected?.accountingFirm,
          companyValue: companySelected?.company,
          isModalOpen: isCompanySelectOpen,
          loadAccountingFirmsOptions: loadAccountingFirmsOptions,
          loadCompaniesOptions: loadCompaniesOptions,
          onSave: (companyData: CompaniesWithAccountingFirm): void => {
            saveCompany(companyData);
          },
          selectedAccountingFirmsOptionFormat: createAccountingFirmOption,
          selectedCompanyOptionFormat: createCompanyOption,
          translations: {
            logout: t("Logout"),
            title: {
              dialog: t("Select company"),
              accompanyFirmDropdown: t("Accounting Firm"),
              companyDropdown: t("Company"),
            },
            button: {
              close: t("Close"),
              save: t("Save"),
            },
            loadingMessage: `${t("Loading")}...`,
            noOptionsMessage: t("No options"),
            placeholder: `${t("Select")}...`,
          },
        }}
        freshDeskWidget={{
          autofill: {
            requester: userData?.email,
            name: userData?.firstName,

            subject: companySelected
              ? `${companySelected?.accountingFirm?.name} - ${companySelected?.company?.internalReference}: ${companySelected?.company?.name}
            `
              : "",
          },
          formTitle: t("Client support"),
          isNewrai: isNewrai,
        }}
        neoactivite={{
          neoActiviteClientHttp: clientNeoActivite,
          neoActiviteClientWS: clientNeoActiviteWS,
          neoActiviteUrl: APP_URL.neoActiviteUrl,
        }}
      />
    </Layout>
  );
}

const HeaderWrapperMemo = React.memo(HeaderWrapper);

export default HeaderWrapperMemo;
