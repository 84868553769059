import newraiANTDPackageEN from "@newrai/ant-design/lib/resources/i18n/locales/en/newraiANTDPackage.json";
import newraiANTDPackageFR from "@newrai/ant-design/lib/resources/i18n/locales/fr/newraiANTDPackage.json";
import docPreviewPackageEN from "@newrai/documents-preview/lib/resources/locales/en/docPreviewPackage.json";
import docPreviewPackageFR from "@newrai/documents-preview/lib/resources/locales/fr/docPreviewPackage.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationEN from "resources/i18n/locales/en/translation.json";
import translationFR from "resources/i18n/locales/fr/translation.json";

const resources = {
  en: {
    translation: translationEN,
    newraiANTDPackage: newraiANTDPackageEN,
    docPreviewPackage: docPreviewPackageEN,
  },
  fr: {
    translation: translationFR,
    newraiANTDPackage: newraiANTDPackageFR,
    docPreviewPackage: docPreviewPackageFR,
  },
};

export const langsCode = ["fr", "en"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || langsCode[0],
    fallbackLng: langsCode,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: ["translation", "newraiANTDPackage", "docPreviewPackage"],
    defaultNS: "translation",
  });

export const langsLabel = () => [i18n.t("French"), i18n.t("English")];

export default i18n;
